import React from "react";
import "reflect-metadata";
import Cookies from "js-cookie";
import { Container } from "typedi";
import { observer } from "mobx-react-lite";
import { Redirect, Switch, useLocation } from "react-router";
import { useAsync, useEffectOnce } from "react-use";

import Loading from "primitives/Loading";

import { UserService } from "services/user";
import { AppStateService } from "services/appState";
import { AlarmMessagesService } from "services/alarmMessages";
import { AutomationObjectsService } from "services/automationObjects";

import { useEventEmitter } from "utils/hooks/useEventEmitter";

import { renderRoutes } from "./renderRoutes";
import { authorizedRoutes, unauthorizedRoutes } from "./routes";

import { globalEventBus } from "common/global";

const userService = Container.get(UserService);
const appStateService = Container.get(AppStateService);
const alarmMessagesService = Container.get(AlarmMessagesService);
const automationObjectsService = Container.get(AutomationObjectsService);

const App = React.memo(() => {
  const { loading } = useAsync(async () => {
    await automationObjectsService.getAutomationObjectsData();

    const AOArray = JSON.parse(JSON.stringify(automationObjectsService.automationObjectsData));
    const { id } = AOArray.find((item: any) => item.ownerId === userService.currentUser.id);
    const activeAO = automationObjectsService.findAutomationObject(id);

    automationObjectsService.setActiveAutomationObject(activeAO);
  }, []);

  useEventEmitter(globalEventBus, "UPDATE_AUTOMATION_OBJECTS", () => {
    automationObjectsService.getAutomationObjectsData();
  });

  useEventEmitter(globalEventBus, "ADD_ALARM_MESSAGE_TO_TOP_BAR", ({ alarmMessage }) => {
    if (alarmMessage.isRepeat) return;

    automationObjectsService.setAutomationObjectData(
      automationObjectsService.automationObjectsData.map((automationObject) => ({
        ...automationObject,
        unconfirmedAlarmsCount:
          automationObject.id === alarmMessage.bindingNode.automationObjectId
            ? automationObject.unconfirmedAlarmsCount + 1
            : automationObject.unconfirmedAlarmsCount,
      })),
    );
  });

  useAsync(async () => {
    await automationObjectsService.getUnconfirmedAlarmsCount();
    await alarmMessagesService.connectToAlarmMessagesHub();
    await alarmMessagesService.subscribeToAlarmMessages();
  });

  useEffectOnce(() => {
    Cookies.set("lastVisit", new Date().toISOString());
  });

  if (loading) return <Loading page />;

  return (
    <>
      <Switch>
        {renderRoutes(authorizedRoutes)}
        <Redirect to={appStateService.getNotFoundUrl()} />
      </Switch>
    </>
  );
});

function AppWrapper() {
  const { pathname } = useLocation();

  const { loading } = useAsync(async () => {
    await userService.getCurrentUser();
  }, []);

  if (loading) return <Loading page />;

  if (!userService.currentUser) {
    return (
      <>
        <Switch>
          {renderRoutes(unauthorizedRoutes)}
          <Redirect to={appStateService.getAuthUrl()} />
        </Switch>
        {!Cookies.get("lastVisit") && pathname !== appStateService.getSupportUrl() ? (
          <Redirect to={appStateService.getSignupUrl()} />
        ) : null}
      </>
    );
  }

  return <App />;
}

export default React.memo(observer(AppWrapper));
