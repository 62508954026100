import { Service } from "typedi";
import { array } from "jsonous";

import { AutomationObjectEntity } from "entities/AutomationObject";
import { TokenEntity } from "entities/Token";

import requestManager from "services/requestManager";
import { automationObjectDecoder } from "services/api/automationItems/decoders";

import { METHODS, toInstanceDecoder } from "utils/worksolutions-utils";

export interface CreateAutomationObjectDataInterface {
  name: string;
  address: string;
  latitude: number;
  altitude: number;
  comment?: string;
}

export interface UpdateAutomationObjectDataInterface {
  id: string;
  name: string;
  address: string;
  comment?: string;
  latitude?: number;
  altitude?: number;
  tokens?: TokenEntity[];
}

@Service()
export class AutomationObjectsAPI {
  private makeGetAutomationObjectsRequest = requestManager.createRequest({
    url: "/api/automation-objects",
    method: METHODS.GET,
    serverDataDecoder: array(automationObjectDecoder.andThen(toInstanceDecoder(AutomationObjectEntity))),
  });

  async getAutomationObjects() {
    return await this.makeGetAutomationObjectsRequest();
  }

  private makeCreateAutomationObjectRequest = requestManager.createRequest({
    url: "/api/automation-objects",
    method: METHODS.POST,
  });

  async createAutomationObject(body: CreateAutomationObjectDataInterface) {
    return await this.makeCreateAutomationObjectRequest({ body });
  }

  private makeUpdateAutomationObjectRequest = requestManager.createRequest({
    url: "/api/automation-objects",
    method: METHODS.PUT,
    serverDataDecoder: automationObjectDecoder.andThen(toInstanceDecoder(AutomationObjectEntity)),
  });

  async updateAutomationObject(body: UpdateAutomationObjectDataInterface) {
    return await this.makeUpdateAutomationObjectRequest({ body });
  }
}
