import { memoizeWith } from "ramda";

import { string1 } from "utils/worksolutions-utils";

const firstToastTop = 40;
export const toastHeight = 100;
export const toastMarginBottom = 24;

export const calcToastTop = memoizeWith(
  string1,
  (index: number) => index * (toastMarginBottom + toastHeight) + firstToastTop,
);
