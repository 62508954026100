import { createThemeContract, globalStyle, style } from "@vanilla-extract/css";

import { globalThemeColorVars } from "theme/theme.css";

export const loadingThemeVars = createThemeContract({ spinnerColor: null });

export const overlayStyles = style({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: globalThemeColorVars.white,
  opacity: 0.85,
  zIndex: 100,
});

export const pageOverlayStyles = style({ position: "fixed" });

globalStyle(`${overlayStyles} .MuiCircularProgress-colorPrimary`, {
  color: loadingThemeVars.spinnerColor,
});
