import { makeAutoObservable } from "mobx";

export enum TagType {
  BIT = "BIT",
  BYTE = "BYTE",
  INT = "INT",
  UINT = "UINT",
  DINT = "DINT",
  UDINT = "UDINT",
  FLOAT = "FLOAT",
  DOUBLE = "DOUBLE",
  STRING = "STRING",
}

export class TagEntity {
  constructor() {
    makeAutoObservable(this);
  }

  alias!: string;

  group!: string;

  name!: string;

  patternValue!: string;

  type!: TagType;
}
