import { memoizeWith } from "ramda";

export enum AcceptTypes {
  IMAGE = "image/*",
  JPEG = "image/jpeg",
  PNG = "image/png",
  ALL = "*",
}

export const makeInputAccept = memoizeWith(
  (accepts) => accepts.join(""),
  (accepts: AcceptTypes[]) => accepts.join(","),
);
