import { StyleRule } from "@vanilla-extract/css";

import { TypographyTypes } from "../types";

const fontWightMap: Record<string, { fontWeight: StyleRule["fontWeight"] }> = {
  bold: { fontWeight: 700 },
  medium: { fontWeight: 500 },
  regular: { fontWeight: 400 },
};

const fontSizeMap: Record<string, StyleRule> = {
  "56/64": {
    fontSize: "56px",
    lineHeight: "64px",
  },
  "48/56": {
    fontSize: "48px",
    lineHeight: "56px",
  },
  "40/48": {
    fontSize: "40px",
    lineHeight: "48px",
  },
  "32/40": {
    fontSize: "32px",
    lineHeight: "40px",
  },
  "24/32": {
    fontSize: "24px",
    lineHeight: "32px",
  },
  "20/28": {
    fontSize: "20px",
    lineHeight: "28px",
  },
  "16/24": {
    fontSize: "16px",
    lineHeight: "24px",
  },
  "13/20": {
    fontSize: "13px",
    lineHeight: "20px",
  },
  "10/13": {
    fontSize: "10px",
    lineHeight: "13px",
  },
  "9/12": {
    fontSize: "9px",
    lineHeight: "12px",
  },
};

export const typographyStylesByType: Record<TypographyTypes, StyleRule> = {
  "Heading-1": { ...fontSizeMap["56/64"], ...fontWightMap.bold },
  "Heading-2": { ...fontSizeMap["48/56"], ...fontWightMap.bold },
  "Heading-3": { ...fontSizeMap["40/48"], ...fontWightMap.bold },
  "Heading-4": { ...fontSizeMap["32/40"], ...fontWightMap.bold },
  "Heading-5": { ...fontSizeMap["24/32"], ...fontWightMap.bold },
  "Heading-6": { ...fontSizeMap["20/28"], ...fontWightMap.bold },
  "Paragraph-1": { ...fontSizeMap["20/28"], ...fontWightMap.regular },
  "Paragraph-1-Bold": { ...fontSizeMap["20/28"], ...fontWightMap.bold },
  "Paragraph-2": { ...fontSizeMap["16/24"], ...fontWightMap.regular },
  "Paragraph-2-Medium": { ...fontSizeMap["16/24"], ...fontWightMap.medium },
  "Paragraph-3": { ...fontSizeMap["13/20"], ...fontWightMap.regular },
  "Paragraph-3-Bold": { ...fontSizeMap["13/20"], ...fontWightMap.bold },
  "Paragraph-4": { ...fontSizeMap["10/13"], ...fontWightMap.regular },
  "Paragraph-4-Bold": { ...fontSizeMap["10/13"], ...fontWightMap.bold },
  "Paragraph-5": { ...fontSizeMap["9/12"], ...fontWightMap.regular },
};
