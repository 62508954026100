import React from "react";
import { Route } from "react-router";

import AsyncModuleRenderer from "AsyncModuleRenderer";

import { RoutesConfig } from "./routes";

export function renderRoutes(routes: RoutesConfig[]) {
  return routes.map((route) => (
    <Route key={route.moduleName} path={route.path} exact={route.exact}>
      <AsyncModuleRenderer moduleName={`modules/${route.moduleName}`} />
    </Route>
  ));
}
