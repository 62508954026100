import React from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import cn from "classnames";

import Loading from "primitives/Loading";
import Icon, { IconInterface } from "primitives/Icon";

import { stringOrPixels } from "utils/styles";

import {
  buttonIconStyles,
  buttonLoadingStyles,
  buttonLoadingWrapperStyles,
  buttonSizeStyles,
  buttonSizeVars,
  buttonStyles,
  buttonThemeVars,
  buttonWithIconStyles,
} from "./styles/index.css";
import { buttonThemeStyles } from "./styles/themes.css";

import { ButtonSize, ButtonMode } from "./enums";

export interface ButtonInterface extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  tabIndex?: number;
  mode?: ButtonMode;
  size?: ButtonSize;
  IconProps?: IconInterface;
  width?: string | number;
  height?: string | number;
  style?: any;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (() => void) | ((event: React.MouseEvent<any>) => void);
}

export const buttonSizeMap = {
  [ButtonSize.EXTRA_SMALL]: "24px",
  [ButtonSize.SMALL]: "32px",
  [ButtonSize.MEDIUM]: "44px",
  [ButtonSize.LARGE]: "56px",
};

function Button(
  {
    className,
    children,
    tabIndex,
    mode = ButtonMode.PRIMARY,
    size = ButtonSize.MEDIUM,
    IconProps,
    width = "auto",
    type = "button",
    height,
    loading,
    style,
    ...props
  }: ButtonInterface,
  ref: React.Ref<any>,
) {
  return (
    <button
      ref={ref}
      tabIndex={tabIndex}
      type={type}
      className={cn(
        buttonStyles,
        buttonThemeStyles[mode],
        buttonSizeStyles[size],
        loading && buttonLoadingStyles,
        IconProps?.icon && buttonWithIconStyles,
        className,
      )}
      style={{
        ...style,
        ...assignInlineVars({
          [buttonSizeVars.width]: stringOrPixels(width),
          [buttonSizeVars.height]: height ? stringOrPixels(height) : buttonSizeMap[size],
        }),
      }}
      {...props}
    >
      <>
        {IconProps?.icon && <Icon className={buttonIconStyles} {...IconProps} />}
        {loading && <Loading className={buttonLoadingWrapperStyles} color={buttonThemeVars.spinnerColor} />}
        {children}
      </>
    </button>
  );
}

export default React.memo(React.forwardRef(Button));

export * from "./enums";
