import { useLayoutEffect, useRef } from "react";

import { stringOrPixels } from "utils/styles";

function nativeSize(viewBox: string) {
  const [minX, minY, width, height] = viewBox.split(" ").map(parseFloat);
  return { minX, minY, width, height };
}

function useIconSize(propWidth?: number | string, propHeight?: number | string) {
  const iconRef = useRef<SVGSVGElement>(null!);

  useLayoutEffect(() => {
    if (!iconRef.current) return;

    const viewBox = iconRef.current.getAttribute("viewBox")!;
    const { width, height } = nativeSize(viewBox!);

    const normalizeHeight = stringOrPixels(propHeight || height);
    const normalizeWidth = stringOrPixels(propWidth || width);

    iconRef.current.setAttribute("height", normalizeHeight);
    iconRef.current.setAttribute("width", normalizeWidth);
  }, [iconRef.current, propHeight, propWidth]); //eslint-disable-line

  return { iconRef };
}

export default useIconSize;
