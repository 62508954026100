import { Service } from "typedi";

@Service()
export class AppStateService {
  iMasks = {
    phoneNumber: { mask: "+# (000) 000 00 00", definitions: { "#": /[1-9]/ } },
    inn: { mask: "000000000000" },
    kpp: { mask: "000000000" },
    bik: { mask: "00-00-00-000" },
    rs: { mask: "000-00-000-0-0000-0000000" },
    ks: { mask: "00000000000000000000" },
  };

  tokensTablePageSizes: number[] = [5, 10, 15, 20, 25];

  tagHandlersTablePageSizes: number[] = [5, 10, 15, 20, 25];

  alarmsTablePageSizes: number[] = [10, 25, 50, 100];

  alarmMessagesPageSize: number = 15;

  logsTablePageSizes: number[] = [10, 25, 50, 100];

  userLogsVersion = 1;

  systemLogsVersion = 1;

  getAuthUrl() {
    return "/auth";
  }

  getSupportUrl() {
    return "/support";
  }

  getSignupUrl() {
    return "/auth/signup";
  }

  getSignupPersonUrl() {
    return "/auth/signup/person";
  }

  getSignupOrganizationUrl() {
    return "/auth/signup/organization";
  }

  getSignupSuccessUrl() {
    return "/auth/signup/success";
  }

  getForgotPasswordUrl() {
    return "/auth/forgot-password";
  }

  getResetPasswordUrl() {
    return "/auth/resetpassword";
  }

  getPersonalAreaPageUrl() {
    return "/personal-area";
  }

  getSettingsPageUrl() {
    return "/personal-area/settings";
  }

  getMainAppPageUrl() {
    return "/";
  }

  getVisualizationPageUrl() {
    return "/visualization-page";
  }

  getMobileVisualizationPageUrl() {
    return "/mobile-visualization-page";
  }

  getAlarmsPageUrl() {
    return "/alarms";
  }

  getLogsPageUrl() {
    return "/logs";
  }

  getNotFoundUrl() {
    return "/not-found";
  }

  getPrivacyPolicyLink() {
    return "#";
  }

  getTermsOfUseLink() {
    return "#";
  }
}
