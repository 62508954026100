import { makeAutoObservable } from "mobx";

import { Photo } from "types/Photo";

export class PersonUserEntity {
  constructor() {
    makeAutoObservable(this);
  }

  id!: string;

  userId!: string;

  email!: string;

  firstName!: string;

  lastName!: string;

  name!: string;

  middleName!: string;

  address!: string;

  birthDate!: Date;

  education!: string;

  organization!: string;

  phoneNumber!: string;

  position!: string;

  avatar!: Photo | null;
}

export class OrganizationUserEntity {
  constructor() {
    makeAutoObservable(this);
  }

  id!: string;

  userId!: string;

  email!: string;

  avatar!: Photo | null;

  phoneNumber!: string;

  name!: string;

  inn!: string;

  kpp!: string;

  country!: string;

  fullAddress!: string;

  bankName!: string;

  city!: string;

  bik!: string;

  rs!: string;

  ks!: string;
}

export type User = PersonUserEntity | OrganizationUserEntity;

export class SimpleUser {
  constructor() {
    makeAutoObservable(this);
  }

  userId!: string;

  name!: string;

  avatar!: Photo | null;
}
