import Decoder, { array, field, number, string, succeed } from "jsonous";
import { assoc } from "ramda";

import { AutomationItemEntity } from "entities/AutomationItem";
import { AutomationObjectEntity } from "entities/AutomationObject";

import {
  fieldOrDefaultDecoder,
  identityValueDecoder,
  isNotNil,
  mergeRightDecoders,
  toInstanceDecoder,
} from "utils/worksolutions-utils";

const baseAutomationObjectDecoder = succeed({})
  .assign("id", field("id", string))
  .assign("ownerId", field("ownerId", string))
  .assign("name", field("name", string))
  .assign("address", field("address", string))
  .assign("latitude", field("latitude", number))
  .assign("altitude", field("altitude", number))
  .assign(
    "photo",
    fieldOrDefaultDecoder(
      "photo",
      succeed({})
        .assign("id", field("id", string))
        .assign("url", field("url", string))
        .assign("publicId", field("publicId", string)),
      undefined,
    ),
  );

const automationItemsFieldDecoder = fieldOrDefaultDecoder(
  "automationItems",
  array(
    identityValueDecoder.map<AutomationItemEntity | null>((rawChild) => {
      const decodedChild = automationItemDecoder.decodeAny(rawChild).getOrElseValue(null!);
      if (!decodedChild) return null;
      return assoc("automationItems", automationItemsFieldDecoder.decodeAny(rawChild).getOrElseValue([]), decodedChild);
    }),
  ).map((children) => children.filter(isNotNil)),
  [],
) as Decoder<AutomationItemEntity[]>;

export const automationObjectDecoder = mergeRightDecoders(
  baseAutomationObjectDecoder,
  succeed({}).assign("automationItems", automationItemsFieldDecoder),
).map((data) => ({ ...data, unconfirmedAlarmsCount: 0 }));

export const automationItemDecoder = succeed({})
  .assign("id", field("id", string))
  .assign("tag", field("tag", string))
  .assign("factor", field("factor", number))
  .assign("automationObjectId", fieldOrDefaultDecoder("automationObjectId", string, undefined))
  .assign(
    "automationObject",
    fieldOrDefaultDecoder(
      "automationObject",
      automationObjectDecoder.andThen(toInstanceDecoder(AutomationObjectEntity)),
      undefined,
    ),
  );
