import { Inject, Service } from "typedi";
import { makeAutoObservable } from "mobx";
import { differenceWith, flatten } from "ramda";
import Cookies from "js-cookie";

import { User } from "entities/User";

import { UserInfoAPI } from "services/api/userInfo/UserInfoAPI";
import {
  AuthAPI,
  ChangePasswordRequestData,
  LoginRequestData,
  ResetPasswordRequestData,
  SignupOrganizationUserDataInterface,
  SignupPersonUserDataInterface,
} from "services/api/auth/AuthAPI";
import { FriendsService } from "services/friends";

@Service()
export class UserService {
  constructor() {
    makeAutoObservable(this);
  }

  currentUser!: User;

  @Inject()
  private userInfoAPI!: UserInfoAPI;

  @Inject()
  private authAPI!: AuthAPI;

  @Inject()
  private friendsService!: FriendsService;

  async login(data: LoginRequestData) {
    const token = await this.authAPI.loginRequest(data);
    Cookies.set("token", "Bearer " + token);
  }

  logout() {
    Cookies.remove("token");
    this.currentUser = undefined!;
  }

  async signupPersonUser(data: SignupPersonUserDataInterface) {
    await this.authAPI.signupPersonUserRequest(data);
  }

  async signupOrganizationUser(data: SignupOrganizationUserDataInterface) {
    await this.authAPI.signupOrganizationUserRequest(data);
  }

  async forgotPassword(email: string) {
    await this.authAPI.forgotPasswordRequest(email);
  }

  async resetPassword(data: ResetPasswordRequestData) {
    await this.authAPI.resetPasswordRequest(data);
  }

  async changePassword(data: ChangePasswordRequestData) {
    await this.authAPI.changePasswordRequest(data);
  }

  getCurrentUser = async () => {
    if (Cookies.get("token")) {
      this.currentUser = await this.userInfoAPI.getCurrentUser();
    }
  };

  async getUsersByFullName(fullName: string) {
    const foundUsers = await this.userInfoAPI.getUsersByFullName(fullName);
    return differenceWith((a, b) => a.id === b.id, flatten([foundUsers]), [
      ...this.friendsService.friendsData,
      this.currentUser,
    ]);
  }

  async updateUserInfo(data: Partial<User>) {
    await this.userInfoAPI.updateUserInfoRequest(data);
  }
}
