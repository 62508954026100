import { styleVariants } from "@vanilla-extract/css";

import { globalThemeColorVars } from "theme/theme.css";

import { buttonThemeVars } from "./index.css";

import { ButtonMode } from "../enums";

export const buttonThemeStyles = styleVariants({
  [ButtonMode.PRIMARY]: {
    vars: {
      [buttonThemeVars.borderColor]: globalThemeColorVars.buttonPrimaryBorder,
      [buttonThemeVars.spinnerColor]: globalThemeColorVars.white,
      [buttonThemeVars.fgColor]: globalThemeColorVars.buttonPrimaryFg,
    },
    backgroundColor: globalThemeColorVars.buttonPrimaryBg,
    selectors: {
      "&:hover": {
        backgroundColor: globalThemeColorVars.buttonPrimaryBgHover,
      },
      "&:active": {
        backgroundColor: globalThemeColorVars.buttonPrimaryBgActive,
      },
    },
  },
  [ButtonMode.PRIMARY_TWO]: {
    vars: {
      [buttonThemeVars.borderColor]: globalThemeColorVars.buttonPrimaryTwoBorder,
      [buttonThemeVars.spinnerColor]: globalThemeColorVars.white,
      [buttonThemeVars.fgColor]: globalThemeColorVars.buttonPrimaryTwoFg,
    },
    backgroundColor: globalThemeColorVars.buttonPrimaryTwoBg,
    selectors: {
      "&:hover": {
        backgroundColor: globalThemeColorVars.buttonPrimaryTwoBgHover,
      },
      "&:active": {
        backgroundColor: globalThemeColorVars.buttonPrimaryTwoBgActive,
      },
    },
  },
  [ButtonMode.PRIMARY_WHITE]: {
    vars: {
      [buttonThemeVars.borderColor]: globalThemeColorVars.buttonPrimaryWhiteBorder,
      [buttonThemeVars.spinnerColor]: globalThemeColorVars.primary,
      [buttonThemeVars.fgColor]: globalThemeColorVars.buttonPrimaryWhiteFg,
    },
    backgroundColor: globalThemeColorVars.buttonPrimaryWhiteBg,
    selectors: {
      "&:hover": {
        backgroundColor: globalThemeColorVars.buttonPrimaryWhiteBgHover,
      },
      "&:active": {
        backgroundColor: globalThemeColorVars.buttonPrimaryWhiteBgActive,
      },
    },
  },
  [ButtonMode.PRIMARY_DARK]: {
    vars: {
      [buttonThemeVars.borderColor]: globalThemeColorVars.buttonPrimaryDarkBorder,
      [buttonThemeVars.spinnerColor]: globalThemeColorVars.white,
      [buttonThemeVars.fgColor]: globalThemeColorVars.buttonPrimaryDarkFg,
    },
    backgroundColor: globalThemeColorVars.buttonPrimaryDarkBg,
    selectors: {
      "&:hover": {
        backgroundColor: globalThemeColorVars.buttonPrimaryDarkBgHover,
      },
      "&:active": {
        backgroundColor: globalThemeColorVars.buttonPrimaryDarkBgActive,
      },
    },
  },
  [ButtonMode.SECONDARY]: {
    vars: {
      [buttonThemeVars.borderColor]: globalThemeColorVars.buttonSecondaryBorder,
      [buttonThemeVars.spinnerColor]: globalThemeColorVars.primary,
      [buttonThemeVars.defaultBorderWidth]: "1px",
      [buttonThemeVars.fgColor]: globalThemeColorVars.buttonSecondaryFg,
    },
    backgroundColor: globalThemeColorVars.buttonSecondaryBg,
    selectors: {
      "&:hover": {
        backgroundColor: globalThemeColorVars.buttonSecondaryBgHover,
      },
      "&:active": {
        backgroundColor: globalThemeColorVars.buttonSecondaryBgActive,
      },
    },
  },
  [ButtonMode.SECONDARY_WHITE]: {
    vars: {
      [buttonThemeVars.borderColor]: globalThemeColorVars.buttonSecondaryWhiteBorder,
      [buttonThemeVars.spinnerColor]: globalThemeColorVars.primary,
      [buttonThemeVars.fgColor]: globalThemeColorVars.buttonSecondaryWhiteFg,
    },
    backgroundColor: globalThemeColorVars.buttonSecondaryWhiteBg,
    selectors: {
      "&:hover": {
        backgroundColor: globalThemeColorVars.buttonSecondaryWhiteBgHover,
      },
      "&:active": {
        backgroundColor: globalThemeColorVars.buttonSecondaryWhiteBgActive,
      },
    },
  },
  [ButtonMode.SECONDARY_DARK]: {
    vars: {
      [buttonThemeVars.borderColor]: globalThemeColorVars.buttonSecondaryDarkBorder,
      [buttonThemeVars.spinnerColor]: globalThemeColorVars.black,
      [buttonThemeVars.defaultBorderWidth]: "1px",
      [buttonThemeVars.fgColor]: globalThemeColorVars.buttonSecondaryDarkFg,
    },
    backgroundColor: globalThemeColorVars.buttonSecondaryDarkBg,
    selectors: {
      "&:hover": {
        backgroundColor: globalThemeColorVars.buttonSecondaryDarkBgHover,
      },
      "&:active": {
        backgroundColor: globalThemeColorVars.buttonSecondaryDarkBgActive,
      },
    },
  },
  [ButtonMode.FLAT]: {
    vars: {
      [buttonThemeVars.borderColor]: globalThemeColorVars.buttonFlatBorder,
      [buttonThemeVars.spinnerColor]: globalThemeColorVars.primary,
      [buttonThemeVars.fgColor]: globalThemeColorVars.buttonFlatFg,
    },
    backgroundColor: globalThemeColorVars.buttonFlatBg,
    selectors: {
      "&:hover": {
        backgroundColor: globalThemeColorVars.buttonFlatBgHover,
      },
      "&:active": {
        backgroundColor: globalThemeColorVars.buttonFlatBgActive,
      },
    },
  },
  [ButtonMode.FLAT_WHITE]: {
    vars: {
      [buttonThemeVars.borderColor]: globalThemeColorVars.buttonFlatWhiteBorder,
      [buttonThemeVars.spinnerColor]: globalThemeColorVars.white,
      [buttonThemeVars.fgColor]: globalThemeColorVars.buttonFlatWhiteFg,
    },
    backgroundColor: globalThemeColorVars.buttonFlatWhiteBg,
    selectors: {
      "&:hover": {
        backgroundColor: globalThemeColorVars.buttonFlatWhiteBgHover,
      },
      "&:active": {
        backgroundColor: globalThemeColorVars.buttonFlatWhiteBgActive,
      },
    },
  },
});
