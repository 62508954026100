import { makeAutoObservable } from "mobx";

import { AutomationItemEntity } from "./AutomationItem";

import { Photo } from "types/Photo";

export class AutomationObjectEntity {
  constructor() {
    makeAutoObservable(this);
  }

  id!: string;

  ownerId!: string;

  name!: string;

  address!: string;

  latitude!: number;

  altitude!: number;

  comment?: string;

  photo?: Photo;

  unconfirmedAlarmsCount!: number;

  automationItems!: AutomationItemEntity[];
}
