import { makeAutoObservable } from "mobx";
import { createIntl, createIntlCache } from "react-intl";
import Container, { Service } from "typedi";

@Service()
export class AppIntelService {
  constructor() {
    makeAutoObservable(this);
  }

  private initialLocale = navigator.language;

  private cache = createIntlCache();

  intl = createIntl({ locale: this.initialLocale, defaultLocale: "ru-RU" }, this.cache);

  async uploadMessages() {
    this.intl.messages = await import(`lang/${this.intl.locale}.json`);
  }

  changeLocale(newLocale: string) {
    this.intl.locale = newLocale;
  }
}

export const appIntel = Container.get(AppIntelService).intl;
