export enum ButtonMode {
  PRIMARY = "PRIMARY",
  PRIMARY_TWO = "PRIMARY_TWO",
  PRIMARY_WHITE = "PRIMARY_WHITE",
  PRIMARY_DARK = "PRIMARY_DARK",
  SECONDARY = "SECONDARY",
  SECONDARY_WHITE = "SECONDARY_WHITE",
  SECONDARY_DARK = "SECONDARY_DARK",
  FLAT = "FLAT",
  FLAT_WHITE = "FLAT_WHITE",
}

export enum ButtonSize {
  EXTRA_SMALL = "EXTRA_SMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}
