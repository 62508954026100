import Decoder, { field, string, succeed } from "jsonous";

import { OrganizationUserEntity, PersonUserEntity, SimpleUser } from "entities/User";

import { convertISOToJSDateObject } from "services/api/utils";
import { photoDecoder } from "services/api/files/photoDecoder";

import {
  fieldOrDefaultDecoder,
  identityValueDecoder,
  mergeRightDecoders,
  toInstanceDecoder,
} from "utils/worksolutions-utils";

const baseUserDecoder = succeed({})
  .assign("id", field("id", string))
  .assign("userId", field("userId", string))
  .assign("email", field("email", string))
  .assign("avatar", fieldOrDefaultDecoder("avatar", photoDecoder, null!))
  .assign("phoneNumber", fieldOrDefaultDecoder("phoneNumber", string, ""));

const personDecoder = mergeRightDecoders(
  baseUserDecoder,
  succeed({}).assign(
    "person",
    field(
      "person",
      succeed({})
        .assign("firstName", field("firstName", string))
        .assign("lastName", field("lastName", string))
        .assign("middleName", fieldOrDefaultDecoder("middleName", string, ""))
        .assign("name", ({ firstName, lastName }) => succeed(firstName + " " + lastName))
        .assign("address", fieldOrDefaultDecoder("address", string, ""))
        .assign("education", fieldOrDefaultDecoder("education", string, ""))
        .assign("organization", fieldOrDefaultDecoder("organization", string, ""))
        .assign("position", fieldOrDefaultDecoder("position", string, ""))
        .assign("birthDate", fieldOrDefaultDecoder("birthDate", string, "").map(convertISOToJSDateObject)),
    ),
  ),
).map(({ person, ...user }) => ({ ...user, ...person }));

const organizationDecoder = mergeRightDecoders(
  baseUserDecoder,
  succeed({}).assign(
    "organization",
    field(
      "organization",
      succeed({})
        .assign("name", field("name", string))
        .assign("inn", field("inn", string))
        .assign("kpp", field("kpp", string))
        .assign("country", field("country", string))
        .assign("fullAddress", field("fullAddress", string))
        .assign("bankName", field("bankName", string))
        .assign("city", field("city", string))
        .assign("bik", field("bik", string))
        .assign("rs", field("rs", string))
        .assign("ks", field("ks", string)),
    ),
  ),
).map(({ organization, ...user }) => ({ ...user, ...organization }));

function toCertainUser(user: any) {
  if (user.person) {
    return personDecoder.andThen(toInstanceDecoder(PersonUserEntity)).decodeAny(user);
  } else {
    return organizationDecoder.andThen(toInstanceDecoder(OrganizationUserEntity)).decodeAny(user);
  }
}

export const userDecoder = identityValueDecoder.andThen(
  (user) =>
    new Decoder<ReturnType<ReturnType<typeof toCertainUser>["getOrElseValue"]>>(() => {
      const result = toCertainUser(user);
      return result as any;
    }),
);

export const simpleUserDecoder = succeed({})
  .assign("userId", field("userId", string))
  .assign("name", field("name", string))
  .assign("avatar", fieldOrDefaultDecoder("avatar", photoDecoder, null!))
  .andThen(toInstanceDecoder(SimpleUser));
