import { createThemeContract, style, StyleRule, styleVariants } from "@vanilla-extract/css";

import { typographyStylesByType } from "./fontStyleMaps";

import { TypographyTypes } from "../types";

export const colorThemeContract = createThemeContract({ color: null });

export const commonStyles = style({
  display: "inline-block",
  color: colorThemeContract.color,
});

export const optionalStyles = styleVariants({
  noWrap: { whiteSpace: "nowrap" },
  textDots: { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "block" },
  pointer: { cursor: "pointer" },
  wordBreak: { wordBreak: "break-all" },
});

export const typographyVariants = styleVariants<Record<TypographyTypes, StyleRule>>(typographyStylesByType);
