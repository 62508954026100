import { makeAutoObservable } from "mobx";
import { Inject, Service } from "typedi";

import { User } from "entities/User";

import { FriendsAPI } from "services/api/friends/FriendsAPI";

@Service()
export class FriendsService {
  constructor() {
    makeAutoObservable(this);
  }

  @Inject()
  private friendsAPI!: FriendsAPI;

  friendsData: User[] = [];

  async getFriendsData() {
    this.friendsData = await this.friendsAPI.getFriends();
  }

  async removeFriend(userId: string) {
    await this.friendsAPI.removeFriend(userId);
  }

  async addFriend(userId: string) {
    await this.friendsAPI.addFriend(userId);
  }
}
