import Container from "typedi";

import { AppStateService } from "services/appState";

const appStateService = Container.get(AppStateService);

export type RoutesConfig = {
  path: string;
  exact?: boolean;
  moduleName: string;
};

export const unauthorizedRoutes: RoutesConfig[] = [
  { path: appStateService.getAuthUrl(), exact: false, moduleName: "Auth" },
  { path: appStateService.getSupportUrl(), exact: true, moduleName: "Support" },
];

export const authorizedRoutes: RoutesConfig[] = [
  { path: appStateService.getAuthUrl(), exact: false, moduleName: "Auth" },
  { path: appStateService.getPersonalAreaPageUrl(), exact: false, moduleName: "PersonalArea" },
  { path: appStateService.getNotFoundUrl(), exact: true, moduleName: "NotFound" },
  { path: appStateService.getSupportUrl(), exact: true, moduleName: "Support" },
  { path: appStateService.getMainAppPageUrl(), exact: false, moduleName: "MainAppPage" },
];

export const personalAreaRoutes: RoutesConfig[] = [
  { path: appStateService.getPersonalAreaPageUrl(), exact: true, moduleName: "PersonalArea/pages/PersonalAreaRoot" },
  { path: appStateService.getSettingsPageUrl(), exact: true, moduleName: "PersonalArea/pages/Settings" },
];

export const authModuleRoutes: RoutesConfig[] = [
  { path: appStateService.getAuthUrl(), exact: true, moduleName: "Auth/Login" },
  { path: appStateService.getSignupUrl(), exact: true, moduleName: "Auth/Signup" },
  { path: appStateService.getSignupPersonUrl(), exact: true, moduleName: "Auth/Signup/PersonSignupView" },
  { path: appStateService.getSignupOrganizationUrl(), exact: true, moduleName: "Auth/Signup/OrganizationSignupView" },
  { path: appStateService.getSignupSuccessUrl(), exact: true, moduleName: "Auth/Signup/Success" },
  { path: appStateService.getForgotPasswordUrl(), exact: true, moduleName: "Auth/ForgotPassword" },
  { path: appStateService.getResetPasswordUrl(), exact: true, moduleName: "Auth/ResetPassword" },
];

export const mainAppPageModuleRoutes: RoutesConfig[] = [
  { path: appStateService.getMainAppPageUrl(), exact: true, moduleName: "MainAppPage/pages/EmptyPage" },
  {
    path: `${appStateService.getVisualizationPageUrl()}/:id`,
    exact: true,
    moduleName: "MainAppPage/pages/VisualizationPage",
  },
  {
    path: `${appStateService.getMobileVisualizationPageUrl()}/:id`,
    exact: true,
    moduleName: "MainAppPage/pages/MobileVisualizationPage",
  },
  {
    path: `${appStateService.getAlarmsPageUrl()}/:automationObjectId`,
    exact: true,
    moduleName: "MainAppPage/pages/Alarms",
  },
  { path: appStateService.getLogsPageUrl(), exact: true, moduleName: "MainAppPage/pages/Logs" },
];
