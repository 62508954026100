import { Service } from "typedi";

import webSocketManager from "services/webSocketManager";

@Service()
export class SignalRAPI {
  private createConnectionToAlarmsHub = webSocketManager.createConnection({
    url: "/websocket/hubOfAlarms",
  });

  async connectToAlarmMessagesHub() {
    return await this.createConnectionToAlarmsHub();
  }

  private createConnectionToTagsHub = webSocketManager.createConnection({
    url: "/websocket/hubOfTags?pageId={pageId}",
  });

  async connectToTagsHub(pageId: string) {
    return await this.createConnectionToTagsHub({ urlParams: { pageId } });
  }
}
