import { memoizeWith } from "ramda";
import { Container, Service } from "typedi";

import { mapObjectEntries, string2 } from "utils/worksolutions-utils";

import { Colors, Durations, Shadows, SystemColors, ZIndexes } from "./types";

@Service({ global: true })
export class Theme {
  static shadows: Record<Shadows, string> = {
    default: "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 8px 18px rgba(79, 79, 79, 0.08)",
    error: "0px 4px 4px rgba(255, 196, 196, 0.04), 0px 4px 40px rgba(255, 196, 196, 0.24)",
    success: "0px 4px 4px rgba(39, 174, 96, 0.04), 0px 4px 40px rgba(39, 174, 96, 0.24)",
    hover: "0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 56px rgba(51, 51, 51, 0.16)",
    active: "0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 24px rgba(51, 51, 51, 0.24)",
    defaultDropShadow: "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25)) drop-shadow(0px 8px 18px rgba(79, 79, 79, 0.08))",
  };

  static durations: Record<Durations, string> = {
    m60: "60ms",
    m80: "80ms",
    m140: "140ms",
    m200: "200ms",
    m350: "350ms",
    m400: "400ms",
    m500: "500ms",
    m600: "600ms",
    m700: "700ms",
    m800: "800ms",
    m900: "900ms",
    m1000: "1000ms",
    m2000: "2000ms",
    m3000: "3000ms",
    m5000: "5000ms",
  };

  static zIndexes: Record<ZIndexes, number> = {
    toastIndex: 99999,
    modalIndex: 30,
  };

  private static colorsRGBValue: Record<SystemColors, string> = {
    primary: "143, 147, 151",
    primaryDisabled: "238, 241, 243",
    primaryHover: "68, 75, 81",
    primaryActive: "42, 46, 50",
    white: "255, 255, 255",
    gray: "157, 157, 157",
    grayS: "218, 218, 218",
    grayM: "250, 250, 250",
    grayL: "245, 245, 245",
    grayK: "54, 54, 54",
    black: "0, 0, 0",
    redGirl: "249, 227, 227",
    redBoy: "242, 202, 202",
    red: "219, 82, 78",
    superRed: "199, 48, 43",
    green: "148, 193, 74",
    blue: "76, 185, 224",
    orange: "216, 107, 70",
    purple: "110, 65, 226",
    purple2: "187, 172, 226",
    star: "255, 217, 18",
  };

  static createAlphaColor = memoizeWith(string2, (colorName: SystemColors, alpha = 1) => {
    return `rgba(${Theme.colorsRGBValue[colorName]}, ${alpha})`;
  });

  colors!: Record<Colors, string>;

  constructor() {
    this.colors = Theme.generateColors();
  }

  private static generateColors(): Record<Colors, string> {
    const systemColors: Record<SystemColors, string> = mapObjectEntries(Theme.colorsRGBValue, ([key, value]) => [
      key,
      `rgba(${value}, 1)`,
    ]) as Record<SystemColors, string>;

    return {
      ...systemColors,

      buttonPrimaryBg: systemColors.orange,
      buttonPrimaryBgHover: "rgba(223, 92, 48, 1)",
      buttonPrimaryBgActive: "rgba(237, 89, 39, 1)",
      buttonPrimaryBorder: "rgba(249, 134, 95, 1)",
      buttonPrimaryFg: systemColors.white,

      buttonPrimaryTwoBg: systemColors.primary,
      buttonPrimaryTwoBgHover: systemColors.primaryHover,
      buttonPrimaryTwoBgActive: systemColors.primaryActive,
      buttonPrimaryTwoBorder: systemColors.gray,
      buttonPrimaryTwoFg: systemColors.white,

      buttonPrimaryWhiteBg: systemColors.white,
      buttonPrimaryWhiteBgHover: Theme.createAlphaColor("white", 0.88),
      buttonPrimaryWhiteBgActive: systemColors.white,
      buttonPrimaryWhiteBorder: "rgba(157, 157, 157, 1)",
      buttonPrimaryWhiteFg: systemColors.primary,

      buttonPrimaryDarkBg: systemColors.primaryHover,
      buttonPrimaryDarkBgHover: systemColors.primaryActive,
      buttonPrimaryDarkBgActive: systemColors.black,
      buttonPrimaryDarkBorder: systemColors.gray,
      buttonPrimaryDarkFg: systemColors.white,

      buttonSecondaryBg: "transparent",
      buttonSecondaryBgHover: "rgba(110, 65, 226, 0.04)",
      buttonSecondaryBgActive: "rgba(110, 65, 226, 0.08)",
      buttonSecondaryBorder: "rgba(157, 157, 157, 1)",
      buttonSecondaryFg: systemColors.primary,

      buttonSecondaryWhiteBg: "transparent",
      buttonSecondaryWhiteBgHover: "rgba(255, 255, 255, 0.12)",
      buttonSecondaryWhiteBgActive: "rgba(255, 255, 255, 0.16)",
      buttonSecondaryWhiteBorder: "rgba(157, 157, 157, 1)",
      buttonSecondaryWhiteFg: systemColors.white,

      buttonSecondaryDarkBg: "transparent",
      buttonSecondaryDarkBgHover: "rgba(17, 17, 17, 0.04)",
      buttonSecondaryDarkBgActive: "rgba(17, 17, 17, 0.12)",
      buttonSecondaryDarkBorder: "rgba(157, 157, 157, 1)",
      buttonSecondaryDarkFg: systemColors.black,

      buttonFlatBg: "transparent",
      buttonFlatBgHover: "rgba(110, 65, 226, 0.04)",
      buttonFlatBgActive: "rgba(110, 65, 226, 0.16)",
      buttonFlatBorder: "rgba(157, 157, 157, 1)",
      buttonFlatFg: systemColors.primary,

      buttonFlatWhiteBg: "transparent",
      buttonFlatWhiteBgHover: "rgba(255, 255, 255, 0.08)",
      buttonFlatWhiteBgActive: "rgba(255, 255, 255, 0.16)",
      buttonFlatWhiteBorder: "rgba(157, 157, 157, 1)",
      buttonFlatWhiteFg: systemColors.white,
    };
  }
}

export const theme = Container.get(Theme);
