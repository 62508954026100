import React from "react";
import cn from "classnames";
import { assignInlineVars } from "@vanilla-extract/dynamic";

import useIconSize from "./hooks";

import { isDeepEqual, isPureObject } from "utils/worksolutions-utils";
import { useProvideRef } from "utils/hooks/useProvideRef";

import { internalIcons } from "./list";

import { iconStyle, iconThemeContract } from "./styles.css";

export type InternalIcons = keyof typeof internalIcons;

export interface IconInterface {
  icon: InternalIcons;
  className?: string;
  fill?: CSSVarFunction | string;
  stroke?: CSSVarFunction | string;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

function isInternalIcon(icon: string): icon is InternalIcons {
  return isPureObject(internalIcons[icon]);
}

const Icon = React.forwardRef(function (
  { icon, className, width = 24, height = 24, style, fill, stroke, onClick }: IconInterface,
  ref: any,
) {
  const { iconRef } = useIconSize(width, height);
  const resultRef = useProvideRef(ref, iconRef);

  const Icon = React.useMemo(() => {
    if (!icon || !isInternalIcon(icon)) return null;
    return internalIcons[icon].default;
  }, [icon]);

  if (Icon === null) return null;

  return (
    <Icon
      ref={resultRef}
      style={{
        ...assignInlineVars({
          [iconThemeContract.fill]: fill as string,
          [iconThemeContract.stroke]: stroke as string,
        }),
        ...style,
      }}
      className={cn(iconStyle, className)}
      onClick={onClick}
    />
  );
});

export default React.memo(Icon, isDeepEqual);
