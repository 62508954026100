import { Container, Inject, Service } from "typedi";
import { makeAutoObservable } from "mobx";
import { array } from "jsonous";
import { assoc, last, pick, propEq, reverse, update } from "ramda";

import { AlarmMessageEntity } from "entities/AlarmMessage";

import { AlarmsRuntimeModeFiltersService } from "services/filters/AlarmsRuntimeModeFiltersService";
import { AutomationObjectsService } from "services/automationObjects";
import { alarmMessageDecoder } from "services/api/alarms/decoders";
import { SignalRAPI } from "services/api/signalR/SignalRAPI";
import { AlarmsAPI } from "services/api/alarms/AlarmsAPI";
import { AppStateService } from "services/appState";
import { UserService } from "services/user";

import type { ConnectionInterface } from "utils/worksolutions-utils";
import { toInstanceDecoder } from "utils/worksolutions-utils";

import { globalEventBus } from "common/global";

interface AlarmsDataInterface {
  offset: number;
  count: number;
  unreadCount: number;
  data: AlarmMessageEntity[];
}

@Service()
export class AlarmMessagesService {
  constructor() {
    makeAutoObservable(this);
  }

  private static readonly alarmMessagesPageSize = Container.get(AppStateService).alarmMessagesPageSize;

  @Inject()
  private automationObjectsService!: AutomationObjectsService;

  @Inject()
  private userService!: UserService;

  @Inject()
  private alarmsAPI!: AlarmsAPI;

  @Inject()
  private filtersService!: AlarmsRuntimeModeFiltersService;

  @Inject()
  private signalRAPI!: SignalRAPI;

  alarmMessagesHubConnection: ConnectionInterface | undefined;

  preloadedAlarmMessagesData: AlarmsDataInterface = {
    offset: AlarmMessagesService.alarmMessagesPageSize,
    count: 0,
    unreadCount: 0,
    data: [],
  };

  atBottom: boolean = true;

  setAtBottom = (value: boolean) => {
    this.atBottom = value;
  };

  async loadOldAlarmMessages() {
    const { offset, data, unreadCount, count } = this.preloadedAlarmMessagesData;

    if (count && data.length >= count) return;

    const response = await this.alarmsAPI.getAlarmMessagesDataRequest({
      automationObjectId: this.automationObjectsService.activeAutomationObject?.id!,
      filters: this.filtersService.filters,
      offset: -(data.length === 0 ? offset : offset + 1),
      itemId: last(data)?.id,
    });

    this.preloadedAlarmMessagesData = {
      offset,
      unreadCount,
      count: count || response.count,
      data: [...this.preloadedAlarmMessagesData.data.slice(0, -1), ...response.data],
    };
  }

  async loadNewAlarmMessages(itemId: string) {
    const { offset, unreadCount, count } = this.preloadedAlarmMessagesData;

    if (unreadCount === 0) return;

    const response = await this.alarmsAPI.getAlarmMessagesDataRequest({
      automationObjectId: this.automationObjectsService.activeAutomationObject?.id!,
      offset: (unreadCount >= offset ? offset : unreadCount) + 1,
      itemId,
    });

    this.preloadedAlarmMessagesData = {
      offset,
      count: count + response.data.length,
      unreadCount: unreadCount >= offset ? unreadCount - offset : 0,
      data: [...reverse(response.data.slice(1)), ...this.preloadedAlarmMessagesData.data],
    };
  }

  clearPreloadedAlarmMessagesData = () => {
    this.preloadedAlarmMessagesData = {
      offset: AlarmMessagesService.alarmMessagesPageSize,
      count: 0,
      unreadCount: 0,
      data: [],
    };
  };

  async connectToAlarmMessagesHub() {
    this.alarmMessagesHubConnection = await this.signalRAPI.connectToAlarmMessagesHub();
  }

  subscribeToAlarmMessages() {
    if (!this.alarmMessagesHubConnection) return;

    this.alarmMessagesHubConnection.on(
      "Notify",
      (messages) => {
        for (const alarmMessage of messages) {
          const belongActiveAO =
            alarmMessage.bindingNode.automationObjectId === this.automationObjectsService.activeAutomationObject?.id;

          globalEventBus.emit("ADD_ALARM_MESSAGE_TO_TOP_BAR", { alarmMessage });

          if (!belongActiveAO || this.filtersService.hasFilter()) continue;

          if (alarmMessage.isRepeat) {
            this.repeatPreloadedAlarmMessage(alarmMessage.alarmId);
            continue;
          }

          if (!this.atBottom || this.preloadedAlarmMessagesData.unreadCount !== 0) {
            this.incrementUnreadAlarmMessagesCount();
            continue;
          }

          this.pushAlarmMessage(alarmMessage);
        }
      },
      array(alarmMessageDecoder.andThen(toInstanceDecoder(AlarmMessageEntity))),
    );
  }

  confirmAlarmMessages = async (data: AlarmMessageEntity[]) => {
    await this.alarmsAPI.confirmAlarmsRequest(data);

    this.preloadedAlarmMessagesData.data = this.preloadedAlarmMessagesData.data.map((alarmMessage) => {
      const confirmedAlarmMessage = data.find(propEq("id", alarmMessage.id));
      return confirmedAlarmMessage
        ? assoc(
            "viewers",
            [
              ...alarmMessage.viewers,
              {
                ...pick(["userId", "avatar"], this.userService.currentUser),
                name: this.userService.currentUser.name,
              },
            ],
            alarmMessage,
          )
        : alarmMessage;
    });
  };

  private setPreloadedAlarmMessagesData = (data: AlarmsDataInterface) => {
    this.preloadedAlarmMessagesData = data;
  };

  private repeatPreloadedAlarmMessage = (alarmId: string) => {
    const repeatedAlarmMessageIndex = this.preloadedAlarmMessagesData.data.findIndex(propEq("alarmId", alarmId));

    if (repeatedAlarmMessageIndex === -1) return;
    const repeatedAlarmMessage = this.preloadedAlarmMessagesData.data[repeatedAlarmMessageIndex];

    this.preloadedAlarmMessagesData.data = update(
      repeatedAlarmMessageIndex,
      {
        ...repeatedAlarmMessage,
        repeatsCount: repeatedAlarmMessage.repeatsCount + 1,
      },
      this.preloadedAlarmMessagesData.data,
    );
  };

  private pushAlarmMessage(alarmMessage: AlarmMessageEntity) {
    const { data, unreadCount, count, offset } = this.preloadedAlarmMessagesData;

    this.setPreloadedAlarmMessagesData({
      offset,
      count: count + 1,
      unreadCount: unreadCount,
      data: [alarmMessage, ...data.slice(0, -1)],
    });
  }

  private incrementUnreadAlarmMessagesCount() {
    const { data, unreadCount, count, offset } = this.preloadedAlarmMessagesData;

    this.setPreloadedAlarmMessagesData({
      unreadCount: unreadCount + 1,
      offset,
      count,
      data,
    });
  }
}
