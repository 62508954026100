import { Service } from "typedi";
import { array } from "jsonous";

import requestManager from "services/requestManager";
import { userDecoder } from "services/api/userInfo/decoders";

import { METHODS } from "utils/worksolutions-utils";

@Service()
export class FriendsAPI {
  private makeGetFriendsRequest = requestManager.createRequest({
    url: "/api/friends/GetAll",
    method: METHODS.GET,
    serverDataDecoder: array(userDecoder),
  });

  async getFriends() {
    return await this.makeGetFriendsRequest();
  }

  private makeDeleteFriendRequest = requestManager.createRequest({
    url: "/api/friends",
    method: METHODS.DELETE,
  });

  async removeFriend(userId: string) {
    return await this.makeDeleteFriendRequest({ additionalQueryParams: { id: userId } });
  }

  private makeAddFriendRequest = requestManager.createRequest({
    url: "/api/friends",
    method: METHODS.POST,
    requestConfig: { contentType: "application/json" },
  });

  async addFriend(userId: string) {
    return await this.makeAddFriendRequest({ body: `"${userId}"` });
  }
}
