import { assoc, omit, pick } from "ramda";
import { Service } from "typedi";

import { OrganizationUserEntity, PersonUserEntity, User } from "entities/User";

import requestManager from "services/requestManager";
import { userDecoder } from "services/api/userInfo/decoders";

import { METHODS } from "utils/worksolutions-utils";

@Service()
export class UserInfoAPI {
  private makeGetUserRequest = requestManager.createRequest({
    url: "/api/user-info/CurrentUser",
    method: METHODS.GET,
    serverDataDecoder: userDecoder,
  });

  async getCurrentUser() {
    return await this.makeGetUserRequest();
  }

  private makeGetUserByFullNameRequest = requestManager.createRequest({
    url: "/api/user-info/GetByFullName",
    method: METHODS.GET,
    serverDataDecoder: userDecoder,
  });

  async getUsersByFullName(fullName: string) {
    return await this.makeGetUserByFullNameRequest({ additionalQueryParams: { fullName } });
  }

  private makeUpdateUserRequest = requestManager.createRequest({
    url: "/api/user-info",
    method: METHODS.PUT,
  });

  async updateUserInfoRequest(data: Partial<User>) {
    const commonFields = ["id", "email", "phoneNumber", "avatar"];
    let body = pick(commonFields, data);

    if (data instanceof PersonUserEntity) {
      body = assoc("person", omit(commonFields, data), body);
    } else if (data instanceof OrganizationUserEntity) {
      body = assoc("organization", omit(commonFields, data), body);
    }

    await this.makeUpdateUserRequest({ body });
  }
}
