import { addMethod, number, NumberSchema, string, StringSchema } from "yup";

import {
  validateBik,
  validateINNIndividual,
  validateINNLegalEntity,
  validateKpp,
  validateKs,
  validatePassword,
  validateRs,
  validateEmail,
} from "utils/validators";

export function extendYupMethods() {
  addMethod<NumberSchema>(number, "checkInn", function (this: NumberSchema, msg: string) {
    return this.test({
      name: "checkInn",
      exclusive: false,
      message: msg,
      test: function (value: number | undefined) {
        return validateINNLegalEntity(value) || validateINNIndividual(value);
      },
    });
  });

  addMethod<NumberSchema>(number, "checkKpp", function (this: NumberSchema, msg: string) {
    return this.test({
      name: "checkKpp",
      exclusive: false,
      message: msg,
      test: validateKpp,
    });
  });

  addMethod<StringSchema>(string, "checkBik", function (this: StringSchema, msg: string) {
    return this.test({
      name: "checkBik",
      exclusive: false,
      message: msg,
      test: validateBik,
    });
  });

  addMethod<StringSchema>(string, "checkKs", function (this: StringSchema, ref: any, msg: string) {
    return this.test({
      name: "checkKs",
      exclusive: false,
      message: msg,
      params: { reference: ref.path },
      test: function (value: string | undefined) {
        return validateKs(value, this.resolve(ref));
      },
    });
  });

  addMethod<StringSchema>(string, "checkRs", function (this: StringSchema, ref: any, msg: string) {
    return this.test({
      name: "checkRs",
      exclusive: false,
      message: msg,
      params: { reference: ref.path },
      test: function (value: string | undefined) {
        return validateRs(value, this.resolve(ref));
      },
    });
  });

  addMethod<StringSchema>(string, "email", function (this: StringSchema, msg: string) {
    return this.test({
      name: "email",
      exclusive: false,
      message: msg,
      test: validateEmail,
    });
  });

  addMethod<StringSchema>(string, "checkPassword", function (this: StringSchema, msg: string) {
    return this.test({
      name: "checkPassword",
      exclusive: false,
      message: msg,
      test: validatePassword,
    });
  });
}

declare module "yup" {
  interface NumberSchema {
    checkInn(msg: string): NumberSchema;

    checkKpp(msg: string): StringSchema;
  }

  interface StringSchema {
    checkBik(msg: string): StringSchema;

    checkKs(ref: any, msg: string): StringSchema;

    checkRs(ref: any, msg: string): StringSchema;

    checkPassword(msg: string): StringSchema;

    email(msg: string): StringSchema;
  }
}
