import { field, string } from "jsonous";
import { Service } from "typedi";

import { OrganizationUserEntity } from "entities/User";

import requestManager from "services/requestManager";

import { METHODS } from "utils/worksolutions-utils";

export interface SignupOrganizationUserDataInterface extends Omit<OrganizationUserEntity, "id" | "userId" | "avatar"> {
  password: string;
  confirmPassword: string;
  privacy: boolean;
}

export interface SignupPersonUserDataInterface {
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  confirmPassword: string;
  privacy?: boolean;
}

export interface ChangePasswordRequestData {
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface LoginRequestData {
  email: string;
  password: string;
}

export interface ResetPasswordRequestData {
  email: string;
  password: string;
  confirmPassword: string;
  code: string;
}

@Service()
export class AuthAPI {
  private makeLoginRequest = requestManager.createRequest({
    url: "/auth/login",
    method: METHODS.POST,
    serverDataDecoder: field("token", string),
  });

  async loginRequest(body: LoginRequestData) {
    return await this.makeLoginRequest({
      body,
      options: { disableBeforeErrorMiddlewares: true },
    });
  }

  private makeSignupRequest = requestManager.createRequest({
    url: "/auth/register",
    method: METHODS.POST,
  });

  async signupPersonUserRequest({
    email,
    password,
    confirmPassword,
    privacy,
    ...privatePerson
  }: SignupPersonUserDataInterface) {
    return await this.makeSignupRequest({
      body: {
        email,
        password,
        confirmPassword,
        privatePerson,
      },
    });
  }

  async signupOrganizationUserRequest({
    email,
    password,
    confirmPassword,
    privacy,
    phoneNumber: phone,
    ...organization
  }: SignupOrganizationUserDataInterface) {
    return await this.makeSignupRequest({
      body: {
        email,
        phone,
        password,
        confirmPassword,
        organization,
      },
    });
  }

  private makeForgotPasswordRequest = requestManager.createRequest({
    url: "/auth/forgotpassword",
    method: METHODS.POST,
  });

  async forgotPasswordRequest(email: string) {
    return await this.makeForgotPasswordRequest({ body: { email } });
  }

  private makeResetPasswordRequest = requestManager.createRequest({
    url: "/auth/resetpassword",
    method: METHODS.POST,
  });

  async resetPasswordRequest(body: ResetPasswordRequestData) {
    return await this.makeResetPasswordRequest({ body });
  }

  private makeChangePasswordRequest = requestManager.createRequest({
    url: "/auth/changepassword",
    method: METHODS.POST,
  });

  async changePasswordRequest(body: ChangePasswordRequestData) {
    return await this.makeChangePasswordRequest({ body });
  }
}
