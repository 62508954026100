import { array, boolean, field, number, string, succeed } from "jsonous";
import { assoc, trim } from "ramda";

import { AlarmCondition, AlarmEntity, AlarmSeverity } from "entities/Alarm";
import { AlarmMessageEntity } from "entities/AlarmMessage";

import { convertEnumToNumberedEnum } from "services/api/utils";
import { paginationDecoder } from "services/api/decoders/paginationDecoder";
import { simpleUserDecoder } from "services/api/userInfo/decoders";

import { enumDecoder, fieldOrDefaultDecoder, mergeRightDecoders, toInstanceDecoder } from "utils/worksolutions-utils";

export const baseAlarmDecoder = succeed({})
  .assign("id", field("id", string))
  .assign("automationObjectId", field("automationObjectId", string))
  .assign("aoNodeId", field("aoTreeNode", string))
  .assign("path", fieldOrDefaultDecoder("treePath", string, ""))
  .assign("ingigoTS", field("ingigoTS", boolean))
  .assign("severity", field("severity", enumDecoder(convertEnumToNumberedEnum(AlarmSeverity).serverToFront)))
  .assign("comparingTag", fieldOrDefaultDecoder("comparingTag", string, undefined))
  .assign("comparingConst", fieldOrDefaultDecoder("comparingConst", number, undefined))
  .map(({ automationObjectId, aoNodeId, path, ...alarm }) => ({
    ...alarm,
    bindingNode: { automationObjectId, aoNodeId, path: path.split("/").map(trim) },
  }));

export const alarmDecoder = mergeRightDecoders(
  baseAlarmDecoder,
  succeed({})
    .assign("text", field("text", string))
    .assign("targetTag", field("targetTag", string))
    .assign("condition", field("condition", enumDecoder(convertEnumToNumberedEnum(AlarmCondition).serverToFront))),
);

export const alarmsDecoder = mergeRightDecoders(
  paginationDecoder,
  succeed({}).assign("data", field("data", array(alarmDecoder.andThen(toInstanceDecoder(AlarmEntity))))),
);

const historyAlarmDecoder = mergeRightDecoders(
  baseAlarmDecoder,
  succeed({})
    .assign("startTS", field("startTS", string))
    .assign("endTS", field("endTS", string))
    .assign("alarmId", field("alarmId", string))
    .assign("text", fieldOrDefaultDecoder("text", string, ""))
    .assign("isRepeat", fieldOrDefaultDecoder("repeated", boolean, false))
    .assign("targetTag", fieldOrDefaultDecoder("targetTag", string, ""))
    .assign("repeatsCount", fieldOrDefaultDecoder("repetitionsNumber", number, 1))
    .assign(
      "viewers",
      fieldOrDefaultDecoder(
        "viewers",
        array(
          mergeRightDecoders(
            simpleUserDecoder,
            succeed({}).assign("confirmationTS", fieldOrDefaultDecoder("confirmationTS", string, undefined)),
          ),
        ),
        [],
      ),
    ),
).map<AlarmMessageEntity>((data) => assoc("repeatsCount", data.repeatsCount || 1, data));

export const alarmMessageDecoder = mergeRightDecoders(
  historyAlarmDecoder,
  succeed({}).assign("id", field("historyId", string)),
);

export const historyAlarmsDecoder = succeed({})
  .assign("count", field("count", number))
  .assign("data", field("data", array(historyAlarmDecoder.andThen(toInstanceDecoder(AlarmMessageEntity)))));

export const unconfirmedAlarmsCountDecoder = succeed({})
  .assign("automationObjectId", field("aoId", string))
  .assign("count", field("counts", fieldOrDefaultDecoder("Alarm", number, 0)));
