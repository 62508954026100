import events from "events";
import { createBrowserHistory } from "history";

import { ToastInterface } from "primitives/Toast";

import { AlarmMessageEntity } from "entities/AlarmMessage";

import { EventEmitter } from "utils/worksolutions-utils";

export const browserHistory = createBrowserHistory();

export const globalEventBus = new EventEmitter<{
  ADD_TOAST: ToastInterface;
  UPDATE_AUTOMATION_OBJECT_TREE: undefined;
  UPDATE_AUTOMATION_OBJECTS: undefined;
  OPEN_SIDEBAR: undefined;
  GET_ALARMS_DATA: undefined;
  GET_LOGS_DATA: undefined;
  RELOAD_ALARM_MESSAGES_DATA: undefined;
  ADD_ALARM_MESSAGE_TO_TOP_BAR: { alarmMessage: AlarmMessageEntity };
  ADD_ALARM_MESSAGE_TO_HISTORY: { alarmMessage: AlarmMessageEntity };
  UPDATE_CONTROL_DATA_FIELDS: { fieldName: string; value: any }[];
}>(events);
