import React from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import CircularProgress from "@mui/material/CircularProgress";
import cn from "classnames";

import { globalThemeColorVars } from "theme/theme.css";

import { loadingThemeVars, overlayStyles, pageOverlayStyles } from "./styles.css";

interface LoadingInterface {
  className?: string;
  page?: boolean;
  text?: string;
  color?: CSSVarFunction;
}

function Loading({ className, text, page, color = globalThemeColorVars.primary }: LoadingInterface) {
  return (
    <div
      className={cn(overlayStyles, page && pageOverlayStyles, className)}
      style={color && assignInlineVars({ [loadingThemeVars.spinnerColor]: color })}
    >
      <CircularProgress />
      {text}
    </div>
  );
}

export default React.memo(Loading);
