import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import Loading from "primitives/Loading";

import { loadModule } from "./libs";

interface BlockRendererInterface extends Record<string, any> {
  moduleName: string;
}

function AsyncModuleRenderer({ moduleName, ...props }: BlockRendererInterface) {
  const [BlockComponent, setBlockComponent] = useState<FC<any>>();

  useEffect(() => {
    loadModule(moduleName!, setBlockComponent);
  }, [moduleName]);

  if (!BlockComponent) {
    return <Loading page />;
  }

  return <BlockComponent {...props} />;
}

export default React.memo(observer(AsyncModuleRenderer));
