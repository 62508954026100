import { makeObservable, observable } from "mobx";

import { BaseAlarmEntity } from "./Alarm";
import { SimpleUser } from "./User";

export class AlarmMessageEntity extends BaseAlarmEntity {
  constructor() {
    super();
    makeObservable(this, {
      text: observable,
      targetTag: observable,
      startTS: observable,
      endTS: observable,
      viewers: observable,
      repeatsCount: observable,
    });
  }

  alarmId!: string;

  text!: string;

  targetTag!: string;

  startTS!: string;

  endTS!: string;

  viewers!: (SimpleUser & { confirmationTS?: string })[];

  repeatsCount!: number;

  isRepeat?: boolean;
}
