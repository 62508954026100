import { Service } from "typedi";
import { assocPath, compose, merge, mergeAll, omit } from "ramda";
import { array } from "jsonous";

import { AlarmCondition, AlarmEntity, AlarmSeverity } from "entities/Alarm";
import { AlarmMessagesFilterEntity } from "entities/filters/AlarmMessagesFilter";
import { AlarmsFilterEntity } from "entities/filters/AlarmsFilter";
import { AlarmMessageEntity } from "entities/AlarmMessage";

import requestManager from "services/requestManager";

import { METHODS, toInstanceDecoder } from "utils/worksolutions-utils";

import { PaginationApiInterface } from "types/Pagination";

import { alarmDecoder, alarmsDecoder, historyAlarmsDecoder, unconfirmedAlarmsCountDecoder } from "./decoders";

import { convertEnumToNumberedEnum, deleteObjectEmptyProps, prepareFrontDateIntervalToServer } from "../utils";

@Service()
export class AlarmsAPI {
  private static prepareAlarmDataToServer({
    bindingNode: { aoNodeId, automationObjectId, path },
    ...data
  }: AlarmEntity) {
    return compose(
      assocPath(["severity"], convertEnumToNumberedEnum(AlarmSeverity).frontToServer[data.severity]),
      assocPath(["condition"], convertEnumToNumberedEnum(AlarmCondition).frontToServer[data.condition]),
    )({ ...data, automationObjectId, aoTreeNode: aoNodeId, treePath: path.join("/") });
  }

  private static prepareFiltersToServer(filters: AlarmsFilterEntity | AlarmMessagesFilterEntity = {}) {
    const result = deleteObjectEmptyProps<Record<string, any>>(
      omit(["severity", "condition", "ingigoTS", "startTS", "endTS"], filters),
    );

    if (filters.severity) {
      result.severity = convertEnumToNumberedEnum(AlarmSeverity).frontToServer[filters.severity];
    }

    if ((filters as AlarmsFilterEntity).condition) {
      result.condition =
        convertEnumToNumberedEnum(AlarmCondition).frontToServer[(filters as AlarmsFilterEntity).condition!];
    }

    if (filters.hasOwnProperty("ingigoTS")) {
      result.protocolTS = (filters as AlarmsFilterEntity).ingigoTS;
    }

    if (filters.hasOwnProperty("startTS")) {
      const [startDate, endDate] = prepareFrontDateIntervalToServer((filters as AlarmMessagesFilterEntity).startTS);
      result.startTS_from = startDate;
      result.startTS_to = endDate;
    }

    if (filters.hasOwnProperty("endTS")) {
      const [startDate, endDate] = prepareFrontDateIntervalToServer((filters as AlarmMessagesFilterEntity).endTS);
      result.endTS_from = startDate;
      result.endTS_to = endDate;
    }

    return result;
  }

  private makeGetAlarmsDataRequest = requestManager.createRequest({
    url: "/wc/alarms",
    serverDataDecoder: alarmsDecoder,
  });

  async getAlarmsDataRequest(pagination: Omit<PaginationApiInterface, "count">, filters?: AlarmsFilterEntity) {
    const filtersInServerFormat = AlarmsAPI.prepareFiltersToServer(filters);
    return await this.makeGetAlarmsDataRequest({ additionalQueryParams: merge(pagination, filtersInServerFormat) });
  }

  private makeGetAlarmMessagesDataRequest = requestManager.createRequest({
    url: "/wc/alarms/historyAlarmsById",
    serverDataDecoder: historyAlarmsDecoder,
  });

  async getAlarmMessagesDataRequest({
    automationObjectId,
    offset,
    itemId,
    filters,
  }: {
    automationObjectId: string;
    offset: number;
    itemId?: string;
    filters?: AlarmMessagesFilterEntity;
  }) {
    const filtersInServerFormat = AlarmsAPI.prepareFiltersToServer(filters);
    return await this.makeGetAlarmMessagesDataRequest({
      additionalQueryParams: mergeAll([
        filtersInServerFormat,
        { automationObjectId, alarmHistId: itemId, itemsNumber: offset },
      ]),
    });
  }

  private makeGetAlarmByIdRequest = requestManager.createRequest({
    url: "/wc/alarms/{id}",
    serverDataDecoder: alarmDecoder.andThen(toInstanceDecoder(AlarmEntity)),
  });

  async getAlarmByIdRequest(id: string) {
    return await this.makeGetAlarmByIdRequest({ urlParams: { id } });
  }

  private makeCreateAlarmRequest = requestManager.createRequest({
    url: "/wc/alarms",
    method: METHODS.POST,
  });

  async createAlarmRequest(alarm: Omit<AlarmEntity, "id">) {
    await this.makeCreateAlarmRequest({
      body: AlarmsAPI.prepareAlarmDataToServer(alarm as AlarmEntity),
    });
  }

  private makeDeleteAlarmRequest = requestManager.createRequest({
    url: "/wc/alarms/{id}",
    method: METHODS.DELETE,
  });

  async deleteAlarmRequest(id: string) {
    await this.makeDeleteAlarmRequest({ urlParams: { id } });
  }

  private makeUpdateAlarmRequest = requestManager.createRequest({
    url: "/wc/alarms",
    method: METHODS.PUT,
  });

  async updateAlarmRequest(alarm: AlarmEntity) {
    await this.makeUpdateAlarmRequest({
      body: AlarmsAPI.prepareAlarmDataToServer(alarm),
    });
  }

  private makeGetUnconfirmedAlarmsCountRequest = requestManager.createRequest({
    url: "/wc/alarms/getunconfirmedalarmscounts",
    method: METHODS.GET,
    serverDataDecoder: array(unconfirmedAlarmsCountDecoder),
  });

  async getUnconfirmedAlarmsCountRequest() {
    return await this.makeGetUnconfirmedAlarmsCountRequest();
  }

  private makeConfirmAlarmsRequest = requestManager.createRequest({
    url: "/wc/alarms/confirmAlarm",
    method: METHODS.POST,
  });

  async confirmAlarmsRequest(body: AlarmMessageEntity[]) {
    return await this.makeConfirmAlarmsRequest({
      body: body.map(({ severity, bindingNode: { aoNodeId, automationObjectId, path }, ...alarmMessage }) => ({
        severity: convertEnumToNumberedEnum(AlarmSeverity).frontToServer[severity],
        automationObjectId,
        aoTreeNode: aoNodeId,
        treePath: path.join("/"),
        ...alarmMessage,
      })),
    });
  }
}
