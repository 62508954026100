import { globalStyle } from "@vanilla-extract/css";
import { globalThemeColorVars } from "theme/theme.css";

globalStyle("*", {
  boxSizing: "border-box",
  lineHeight: "0",
  fontFamily: "'GostUI', sans-serif !important",
  // @ts-ignore
  "-webkit-font-smoothing": "antialiased",
});

globalStyle("html", {
  height: "100%",
  overflow: "hidden",
});

globalStyle("body", {
  minHeight: "100%",
  height: "100%",
  overflow: "auto",
});

globalStyle("#root", {
  display: "flex",
  minHeight: "100%",
  backgroundColor: globalThemeColorVars.primaryActive,
});

globalStyle("button", {
  // @ts-ignore
  "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
  // @ts-ignore
  "-webkit-touch-callout": "none",
  // @ts-ignore
  "-webkit-user-select": "none",
});

globalStyle("input[type='number']", {
  // @ts-ignore
  "-moz-appearance": "textfield",
});

globalStyle("input[type='number']::-webkit-outer-spin-button, input[type='number']::-webkit-inner-spin-button", {
  // @ts-ignore
  "-webkit-appearance": "none",
  margin: 0,
});

globalStyle("::-webkit-scrollbar", {
  width: 6,
  height: 6,
});

globalStyle("::-webkit-scrollbar-track", {
  background: `linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 30%,
    ${globalThemeColorVars.primaryDisabled} 50%,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0) 100%
  );`,
});

globalStyle("::-webkit-scrollbar-track:horizontal", {
  background: `linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 30%,
    ${globalThemeColorVars.primaryDisabled} 50%,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0) 100%
  );`,
});

globalStyle("::-webkit-scrollbar-thumb", {
  backgroundColor: globalThemeColorVars.primary,
  borderRadius: 4,
});
