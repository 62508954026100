import { makeObservable, observable } from "mobx";

export enum AlarmSeverity {
  EXCEPTION = "EXCEPTION",
  FAIL = "FAIL",
  WARN = "WARN",
  INFO = "INFO",
}

export enum AlarmCondition {
  EQUALS = "EQUALS",
  NOT_EQUAL = "NOT_EQUAL",
  GREATER = "GREATER",
  LESS = "LESS",
  GREATER_OR_EQUAL = "GREATER_OR_EQUAL",
  LESS_OR_EQUAL = "LESS_OR_EQUAL",
}

export class BaseAlarmEntity {
  constructor() {
    makeObservable(this, {
      id: observable,
      ingigoTS: observable,
      severity: observable,
      comparingTag: observable,
      comparingConst: observable,
      bindingNode: observable,
    });
  }

  id!: string;

  ingigoTS!: boolean;

  severity!: AlarmSeverity;

  bindingNode!: {
    aoNodeId: string;
    automationObjectId: string;
    path: string[];
  };

  comparingTag?: string;

  comparingConst?: number;
}

export class AlarmEntity extends BaseAlarmEntity {
  constructor() {
    super();
    makeObservable(this, {
      text: observable,
      targetTag: observable,
      condition: observable,
    });
  }

  text!: string;

  targetTag!: string;

  condition!: AlarmCondition;
}
