import { FlowElement } from "react-flow-renderer";
import { omit } from "ramda";

import { TagEntity, TagType } from "entities/Tag";
import { MobileControlEntity } from "entities/Control";

import { capitalizeFirstStringCharacter } from "utils/worksolutions-utils";

export function convertEnumToNumberedEnum<VALUES extends string | number>(
  matches: Record<string, VALUES>,
  shift: number = 0,
) {
  const enumValues = Object.values(matches);

  return {
    frontToServer: Object.fromEntries(enumValues.map((value, key) => [value, key + shift])) as Record<VALUES, number>,
    serverToFront: Object.fromEntries(enumValues.map((value, key) => [key + shift, value])) as Record<number, VALUES>,
  };
}

export function convertEnumToCamelizeEnum<VALUES extends string>(matches: Record<string, VALUES>) {
  const enumValues = Object.values(matches);

  return {
    frontToServer: Object.fromEntries(
      enumValues.map((value) => [value, capitalizeFirstStringCharacter(camelize(value))]),
    ),
    serverToFront: Object.fromEntries(
      enumValues.map((value) => [capitalizeFirstStringCharacter(camelize(value)), value]),
    ),
  };
}

function camelize(str: string): string {
  return str.toLowerCase().replace(/_./g, (x) => x[1].toUpperCase());
}

export function deleteObjectEmptyProps<TYPE>(obj: TYPE) {
  const result = { ...obj };

  for (let [key, value] of Object.entries(result)) {
    if (value === undefined) {
      delete result[key as keyof typeof result];
    }
  }

  return result;
}

export function prepareFrontDateIntervalToServer([startDate, endDate]: DateInterval): DateInterval {
  return [startDate && new Date(startDate), endDate && new Date(endDate)];
}

export function convertISOToJSDateObject(isoString: string) {
  return new Date(isoString);
}

function convertObjectToKeyValueArray(obj: Record<string, any> | undefined) {
  if (!obj) return [];
  return Object.entries(obj)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => ({ key, value: JSON.stringify(value) }));
}

export function prepareVisualizationPageElementsToServer(elements: (FlowElement | MobileControlEntity)[]) {
  return elements.map(({ data: { tag, ...data }, style, ...props }) => ({
    ...props,
    connectedTag: tag
      ? {
          ...omit(["type"], tag),
          tagType: convertEnumToNumberedEnum(TagType, 1).frontToServer[(tag as TagEntity).type],
        }
      : undefined,
    data: convertObjectToKeyValueArray(data),
    style: convertObjectToKeyValueArray(style),
  }));
}
