import { createThemeContract, style, styleVariants } from "@vanilla-extract/css";
import { padding, transitions } from "polished";
import { Theme } from "theme/Theme";

import { typographyStylesByType } from "primitives/Typography/css/fontStyleMaps";

import { ButtonSize } from "../enums";

export const buttonThemeVars = createThemeContract({
  borderColor: "transparent",
  defaultBorderWidth: "0px",
  spinnerColor: null,
  fgColor: null,
});

export const buttonSizeVars = createThemeContract({
  width: "auto",
  height: "auto",
});

export const buttonStyles = style({
  position: "relative",
  borderRadius: 4,
  border: 0,
  cursor: "pointer",
  opacity: 1,
  outline: 0,
  width: buttonSizeVars.width,
  minWidth: buttonSizeVars.width,
  height: buttonSizeVars.height,
  minHeight: buttonSizeVars.height,
  color: buttonThemeVars.fgColor,
  overflow: "hidden",
  boxShadow: `inset 0 0 0 ${buttonThemeVars.defaultBorderWidth} ${buttonThemeVars.borderColor}`,
  ...transitions(["all"], Theme.durations.m140),
  ...padding(10, 20),

  ":focus": {
    boxShadow: `inset 0 0 0 2px ${buttonThemeVars.borderColor}`,
  },
  ":disabled": {
    pointerEvents: "none",
    cursor: "default",
    opacity: 0.4,
  },
  ...typographyStylesByType["Paragraph-2"],
});

export const buttonLoadingWrapperStyles = style({
  backgroundColor: "inherit",
  opacity: 1,
});

export const buttonLoadingStyles = style({
  color: "transparent",
  pointerEvents: "none",
  cursor: "default",
});

export const buttonSizeStyles = styleVariants({
  [ButtonSize.EXTRA_SMALL]: { padding: "4px 20px" },
  [ButtonSize.SMALL]: { padding: "5px 20px" },
  [ButtonSize.MEDIUM]: { padding: "10px 20px" },
  [ButtonSize.LARGE]: { padding: "15px 20px" },
});

export const buttonWithIconStyles = style({ paddingLeft: 52 });

export const buttonIconStyles = style({
  position: "absolute",
  left: 20,
  top: "50%",
  transform: "translateY(-50%)",
});
