import { createThemeContract, style, globalStyle } from "@vanilla-extract/css";
import { important } from "polished";

export const iconThemeContract = createThemeContract<{ fill: string; stroke: string }>({
  fill: "",
  stroke: "",
});

export const iconStyle = style({
  ...important({ visibility: "visible" }),
  fill: iconThemeContract.fill,
});

globalStyle(`${iconStyle} > *`, { stroke: iconThemeContract.stroke });
