import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import { RawIntlProvider } from "react-intl";
import { Container } from "typedi";
import { observer } from "mobx-react-lite";
import { useAsync } from "react-use";
import "normalize.css/normalize.css";
import "initialize";

import ToastReceiver from "components/ToastReceiver";
import ToastsController from "components/ToastReceiver/ToastsController";

import App from "modules/App";

import { AppIntelService } from "services/appIntl";

import "./global.css";

import { browserHistory, globalEventBus } from "common/global";

const toastsController = new ToastsController();

const appIntelService = Container.get(AppIntelService);

const AppWrapper = observer(() => {
  useAsync(async () => await appIntelService.uploadMessages(), [appIntelService.intl?.locale]);
  return (
    <RawIntlProvider value={appIntelService.intl}>
      <Router history={browserHistory}>
        <App />
        <ToastReceiver eventEmitter={globalEventBus} toastsController={toastsController} />
      </Router>
    </RawIntlProvider>
  );
});

ReactDOM.render(<AppWrapper />, document.getElementById("root"));
