import { createThemeContract, style } from "@vanilla-extract/css";

import { Theme } from "theme/Theme";
import { opacityShowFade } from "theme/styles/animations.css";

import { toastHeight, toastMarginBottom } from "./libs";

export const toastThemeContract = createThemeContract({
  top: null,
});

export const toastWrapperStyles = style({
  display: "flex",
  justifyContent: "center",
  left: "50%",
  position: "fixed",
  willChange: "opacity",
  transform: "translateX(-50%)",
  animationName: opacityShowFade,
  animationDuration: Theme.durations.m200,
  animationTimingFunction: "linear",
  zIndex: Theme.zIndexes.toastIndex,
  marginBottom: toastMarginBottom,
  height: toastHeight,
  top: toastThemeContract.top,
  maxWidth: "80%",
  boxShadow: Theme.shadows.hover,
  borderRadius: 8,
});

export const toastStyles = style({
  backgroundColor: "white",
  borderRadius: 6,
  borderWidth: 1,
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  gap: 20,
  padding: 20,
});

export const textWrapperStyles = style({
  display: "flex",
  flexDirection: "column",
  gap: 8,
});

export const iconWrapperStyles = style({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 56,
  height: 56,
  borderRadius: "50%",
});
