import { makeAutoObservable } from "mobx";

import { AlarmSeverity } from "../Alarm";

export class AlarmMessagesFilterEntity {
  constructor() {
    makeAutoObservable(this);
  }

  text?: string;

  severity?: AlarmSeverity;

  startTS!: DateInterval;

  endTS!: DateInterval;
}
