import React from "react";
import { FormattedMessage } from "react-intl";
import { assignInlineVars } from "@vanilla-extract/dynamic";

import { makeExcludingDeepEqual } from "utils/worksolutions-utils";

import Icon from "primitives/Icon";
import Typography from "primitives/Typography";
import Button, { ButtonSize, ButtonMode } from "primitives/Button";

import { ToastType } from "./enum";
import { calcToastTop } from "./libs";

import { Theme } from "theme/Theme";
import { globalThemeColorVars } from "theme/theme.css";

import {
  iconWrapperStyles,
  textWrapperStyles,
  toastStyles,
  toastThemeContract,
  toastWrapperStyles,
} from "./styles.css";

const toastTypeAndIconPropsMatch = {
  [ToastType.SUCCESS]: {
    icon: "icon-Checked",
    color: globalThemeColorVars.green,
    backgroundColor: Theme.createAlphaColor("green", 0.12),
  },
  [ToastType.ERROR]: {
    icon: "icon-Close",
    color: globalThemeColorVars.red,
    backgroundColor: globalThemeColorVars.redGirl,
  },
  [ToastType.INFO]: {
    icon: "icon-Attention",
    color: globalThemeColorVars.primary,
    backgroundColor: globalThemeColorVars.primaryDisabled,
  },
};

export interface ToastInterface {
  primaryText?: string;
  secondaryText?: string;
  cancelButton?: boolean;
  type?: ToastType;
}

export interface ToastComponentInterface extends ToastInterface {
  index?: number;
  removeToast: () => void;
}

function Toast({
  index = 0,
  primaryText,
  secondaryText,
  type = ToastType.SUCCESS,
  cancelButton = false,
  removeToast,
}: ToastComponentInterface) {
  const { icon, color, backgroundColor } = React.useMemo(() => toastTypeAndIconPropsMatch[type], [type]);

  return (
    <div
      className={toastWrapperStyles}
      style={assignInlineVars({
        [toastThemeContract.top]: `${calcToastTop(index)}px`,
      })}
    >
      <div className={toastStyles}>
        {(primaryText || secondaryText) && (
          <div className={textWrapperStyles}>
            {primaryText && (
              <Typography type="Paragraph-1-Bold" dots>
                {primaryText}
              </Typography>
            )}
            {secondaryText && <Typography dots>{secondaryText}</Typography>}
          </div>
        )}
        <div className={iconWrapperStyles} style={{ backgroundColor }}>
          <Icon icon={icon} fill={color} />
        </div>
        {cancelButton && (
          <Button width="max-content" size={ButtonSize.MEDIUM} mode={ButtonMode.SECONDARY} onClick={removeToast}>
            <FormattedMessage description="Remove toast button text" defaultMessage="Закрыть" />
          </Button>
        )}
      </div>
    </div>
  );
}

export default React.memo(Toast, makeExcludingDeepEqual(["removeToast"]));
