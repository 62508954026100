export const internalIcons: Record<string, any> = {
  "icon-Arrow-Down-2": require("./icons/arrow-Down-2.svg"),
  "icon-Arrow-Down-Circle": require("./icons/arrow-down-circle.svg"),
  "icon-Arrow-Left-4": require("./icons/arrow-left-4.svg"),
  "icon-Arrow-Right-4": require("./icons/arrow-right-4.svg"),
  "icon-Arrow-Right-Square": require("./icons/arrow-right-square.svg"),
  "icon-Arrow-Left-Square": require("./icons/arrow-left-square.svg"),
  "icon-Danger": require("./icons/danger.svg"),
  "icon-Download": require("./icons/download.svg"),
  "icon-Search": require("./icons/search.svg"),
  "icon-Swap": require("./icons/swap.svg"),
  "icon-Flag": require("./icons/flag.svg"),
  "icon-Rollback": require("./icons/refresh-2.svg"),
  "icon-Fit-View": require("./icons/fit-view.svg"),
  "icon-Zoom-In": require("./icons/zoom-In.svg"),
  "icon-Zoom-Out": require("./icons/zoom-out.svg"),
  "icon-Arrow-Down-4": require("./icons/arrow-down-4.svg"),
  "icon-Category": require("./icons/category.svg"),
  "icon-Delete": require("./icons/delete.svg"),
  "icon-Image": require("./icons/image.svg"),
  "icon-Notification": require("./icons/notification.svg"),
  "icon-Profile": require("./icons/profile-placeholder.svg"),
  "icon-Setting": require("./icons/setting.svg"),
  "icon-Chalkboard": require("./icons/chalkboard.svg"),
  "icon-Checked": require("./icons/checked.svg"),
  "icon-Close": require("./icons/close.svg"),
  "icon-Menu": require("./icons/menu.svg"),
  "icon-Refresh": require("./icons/refresh.svg"),
  "icon-Minus": require("./icons/minus.svg"),
  "icon-Attention": require("./icons/attention.svg"),
  "icon-AO": require("./icons/AO-placeholder.svg"),
  "icon-Edit": require("./icons/edit.svg"),
  "icon-Play-Bg": require("./icons/play-bg.svg"),
  "icon-Simple-Plus": require("./icons/simple-plus.svg"),
  "icon-Check": require("./icons/check.svg"),
  "icon-Runtime-mode": require("./icons/runtime-mode.svg"),
  "icon-Edit-mode": require("./icons/edit-mode.svg"),
  "icon-Plus": require("./icons/plus.svg"),
  "icon-Setting-Empty": require("./icons/setting-2.svg"),
  "icon-Calendar": require("./icons/calendar.svg"),
  "icon-Arrow-left": require("./icons/arrow-left.svg"),
  "icon-Arrow-left-2": require("./icons/arrow-left-2.svg.svg"),
  "icon-Arrow-right": require("./icons/arrow-right.svg"),
  "icon-Arrow-right-2": require("./icons/arrow-right-2.svg.svg"),
  "icon-Arrow-right-3": require("./icons/arrow-right-3.svg"),
  "icon-Arrow-left-circle": require("./icons/arrow-left-circle.svg"),
  "icon-Exclamation-triangle": require("./icons/triangle.svg"),
  "icon-List-ul": require("./icons/list-ul.svg"),
  "icon-Show": require("./icons/show.svg"),
  "icon-Paper": require("./icons/paper.svg"),
  "icon-Visualization-Page": require("./icons/visualization-page.svg"),
  "icon-Mobile-Visualization-Page": require("./icons/mobile-visualization-page.svg"),
  "icon-Create-mobile-Visualization-Page": require("./icons/create-mobile-visualization-page.svg"),
  "icon-404": require("./icons/404.svg"),
  "icon-Not-found": require("./icons/not-found.svg"),
  "icon-Tag": require("./icons/tag.svg"),
  "icon-Person-user": require("./icons/person-user.svg"),
  "icon-Organization-user": require("./icons/organization-user.svg"),
  "icon-Arrow-Up-small": require("./icons/arrow-Up-small.svg"),
  "icon-Arrow-Down-small": require("./icons/arrow-Down-small.svg"),
  "icon-support": require("./icons/support.svg"),
  "icon-machine": require("./icons/machine.svg"),
  "icon-lockOpen": require("./icons/lockOpen.svg"),
  "icon-lockClose": require("./icons/lockClose.svg"),
  "icon-menuDeviceHandler": require("./icons/menuDeviceHandler.svg"),
};
