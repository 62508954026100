import React from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import cn from "classnames";

import { globalThemeColorVars } from "theme/theme.css";

import { colorThemeContract, commonStyles, optionalStyles, typographyVariants } from "./css/index.css";

import { TypographyTypes } from "./types";

export interface TypographyInterface {
  className?: string;
  children: React.ReactNode;
  type?: TypographyTypes;
  as?: React.FC<any> | React.ComponentClass<any> | string;
  noWrap?: boolean;
  color?: CSSVarFunction;
  dots?: boolean;
  wordBreak?: boolean;
  asHTML?: boolean;
  onClick?: (event: React.SyntheticEvent<HTMLAnchorElement, MouseEvent>) => void;
  [name: string]: any;
}

function Typography(
  {
    className,
    children,
    type = "Paragraph-2",
    as = "span",
    noWrap,
    color = globalThemeColorVars.black,
    dots,
    wordBreak,
    asHTML,
    onClick,
    ...props
  }: TypographyInterface,
  ref: React.Ref<HTMLSpanElement>,
) {
  const contentProps = asHTML ? { dangerouslySetInnerHTML: { __html: children } } : { children };

  return React.createElement(as, {
    className: cn(
      commonStyles,
      typographyVariants[type],
      noWrap && optionalStyles.noWrap,
      dots && optionalStyles.textDots,
      wordBreak && optionalStyles.wordBreak,
      onClick && optionalStyles.pointer,
      className,
    ),
    style: assignInlineVars({ [colorThemeContract.color]: color }),
    ref,
    onClick,
    ...props,
    ...contentProps,
  });
}

export default React.memo(React.forwardRef(Typography));

export * from "./types";
