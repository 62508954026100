import { memoizeWith } from "ramda";

import { validateBik } from "./validateBik";
import { string2 } from "../worksolutions-utils";

export const validateKs = memoizeWith(string2, (value: string = "", bik: string = ""): boolean => {
  const valueToString = value ? value.toString() : "";

  if (validateBik(bik)) {
    if (!/[^0-9]/.test(valueToString) && valueToString.length === 20) {
      const bikKs = "0" + bik.slice(4, 6) + valueToString;
      let checkSum = 0;
      const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

      for (const i in coefficients) {
        checkSum += coefficients[i] * (Number(bikKs[i]) % 10);
      }

      return checkSum % 10 === 0;
    }
  }

  return false;
});
