import { configure, makeAutoObservable } from "mobx";
import Cookies from "js-cookie";
import { Container } from "typedi";
import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";

import { ToastType } from "primitives/Toast/enum";

import { AppStateService } from "services/appState";

import {
  AppRequestError,
  Connection,
  ConnectionStatus,
  isPureObject,
  isString,
  RequestManager,
  WebSocketManager,
} from "utils/worksolutions-utils";

import reportWebVitals from "./reportWebVitals";
import { extendYupMethods } from "./extendYupMethods";

import { browserHistory, globalEventBus } from "common/global";

const appStateService = Container.get(AppStateService);

configure({ enforceActions: "never" });

reportWebVitals();
extendYupMethods();

RequestManager.baseURL = process.env.API_HOST || "/";

RequestManager.beforeErrorMiddleware.push(({ error }) => {
  if (error.statusCode === -1) {
    console.error(error);
    return null;
  }

  return error;
});

RequestManager.beforeErrorMiddleware.push(({ error }) => {
  if (error.statusCode !== 401) return error;
  browserHistory.replace(appStateService.getAuthUrl());
  return null;
});

RequestManager.beforeErrorMiddleware.push(({ error, shareData }) => {
  try {
    if (!error.axiosError?.response?.data) return error;
    const { message: errors } = error.axiosError.response.data;
    if (isString(errors)) {
      shareData.decoded = true;
      return new AppRequestError({ message: errors, errors: {} }, error.statusCode, error.axiosError);
    }

    if (!errors || !isPureObject(errors) || Object.keys(errors).length === 0) return error;

    shareData.decoded = true;
    return new AppRequestError(
      {
        message: error.message,
        errors: Object.fromEntries(
          Object.entries(errors).map(([fieldName, error]) => [
            fieldName,
            isString(error) ? error : JSON.stringify(error),
          ]),
        ),
      },
      error.statusCode,
      error.axiosError,
    );
  } catch (e) {}

  return error;
});

RequestManager.beforeRequestMiddleware.push(({ config }) => {
  const token = Cookies.get("token");
  if (!token) return;
  config.headers.Authorization = token;
});

WebSocketManager.baseURL = process.env.WS_HOST || "/";

const connectionStatusMap: Record<HubConnectionState, ConnectionStatus> = {
  [HubConnectionState.Disconnected]: ConnectionStatus.DISCONNECTED,
  [HubConnectionState.Connecting]: ConnectionStatus.CONNECTING,
  [HubConnectionState.Connected]: ConnectionStatus.CONNECTED,
  [HubConnectionState.Disconnecting]: ConnectionStatus.DISCONNECTING,
  [HubConnectionState.Reconnecting]: ConnectionStatus.RECONNECTING,
};

WebSocketManager.internalSocketManager = {
  connect: async ({ url, baseURL }) => {
    const connection = makeAutoObservable(
      new HubConnectionBuilder()
        .withUrl(baseURL + url, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => Cookies.get("token")?.replace("Bearer ", "") || "",
        })
        .configureLogging(LogLevel.None)
        .withAutomaticReconnect()
        .build(),
    );

    await connection.start();

    return new Connection({
      onHandler: (methodName: string, newMethod: (...args: any[]) => void) => connection.on(methodName, newMethod),
      emitHandler: (methodName: string, ...args: any[]) => connection.send(methodName, ...args),
      getStatusHandler: () => connectionStatusMap[connection.state],
      closeConnectionHandler: async () => await connection.stop(),
    });
  },
};

if (process.env.NODE_ENV === "development") {
  RequestManager.beforeErrorMiddleware.push(({ error, shareData }) => {
    if (!shareData.decoded) return error;

    if (error.hasErrors()) {
      const errors = Object.entries(error.errors);
      errors.forEach(([errorKey, errorText]) =>
        globalEventBus.emit("ADD_TOAST", {
          cancelButton: true,
          type: ToastType.ERROR,
          secondaryText: `Ошибка при отправке запроса. Поле «${errorKey}»: ${errorText}`,
        }),
      );
    } else {
      globalEventBus.emit("ADD_TOAST", {
        cancelButton: true,
        type: ToastType.ERROR,
        secondaryText: `Ошибка при отправке запроса: «${error.message}»`,
      });
    }

    return null;
  });

  RequestManager.beforeErrorMiddleware.push(({ error, config }) => {
    globalEventBus.emit("ADD_TOAST", {
      cancelButton: true,
      type: ToastType.ERROR,
      secondaryText: `${config.method!.toUpperCase()} ${config.url} --- ${error.message}`,
    });

    return null;
  });

  WebSocketManager.addBeforeErrorMiddleware("add-toast", ({ error, config }) => {
    globalEventBus.emit("ADD_TOAST", {
      cancelButton: true,
      type: ToastType.ERROR,
      secondaryText: `${config.url} --- ${error.message}`,
    });

    return null;
  });
}
