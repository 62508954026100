import { makeAutoObservable } from "mobx";
import { Inject, Service } from "typedi";
import { propEq } from "ramda";

import { AutomationObjectEntity } from "entities/AutomationObject";

import {
  AutomationObjectsAPI,
  CreateAutomationObjectDataInterface,
  UpdateAutomationObjectDataInterface,
} from "services/api/automationObjects/AutomationObjectsAPI";
import { AlarmsAPI } from "services/api/alarms/AlarmsAPI";
import { UserService } from "services/user";

@Service()
export class AutomationObjectsService {
  constructor() {
    makeAutoObservable(this);
  }

  @Inject()
  private userService!: UserService;

  @Inject()
  private alarmsAPI!: AlarmsAPI;

  @Inject()
  private automationObjectsAPI!: AutomationObjectsAPI;

  activeAutomationObject!: AutomationObjectEntity | undefined;

  automationObjectsData: AutomationObjectEntity[] = [];

  async getAutomationObjectsData() {
    this.automationObjectsData = await this.automationObjectsAPI.getAutomationObjects();
  }

  async getUnconfirmedAlarmsCount() {
    const response = await this.alarmsAPI.getUnconfirmedAlarmsCountRequest();
    const unconfirmedAlarmsCountMap = Object.fromEntries(
      response.map(({ automationObjectId, count }) => [automationObjectId, count]),
    );

    this.setAutomationObjectData(
      this.automationObjectsData.map((automationObject) => ({
        ...automationObject,
        unconfirmedAlarmsCount: unconfirmedAlarmsCountMap[automationObject.id] || 0,
      })),
    );
  }

  async createAutomationObject(data: CreateAutomationObjectDataInterface) {
    await this.automationObjectsAPI.createAutomationObject(data);
  }

  async updateAutomationObject(data: UpdateAutomationObjectDataInterface) {
    this.activeAutomationObject = await this.automationObjectsAPI.updateAutomationObject(data);
  }

  setAutomationObjectData = (data: AutomationObjectEntity[]) => {
    this.automationObjectsData = data;
  };

  findAutomationObject = (automationObjectId: string) => {
    return this.automationObjectsData.find(propEq("id", automationObjectId));
  };

  setActiveAutomationObject = (newValue: AutomationObjectEntity | undefined) => {
    this.activeAutomationObject = newValue;
  };

  clearActiveAutomationObject = () => {
    this.activeAutomationObject = undefined;
  };

  getActiveAutomationObject = () => {
    if (this.activeAutomationObject) {
      return this.activeAutomationObject.id;
    }
    return "";
  };
}
