import { Service } from "typedi";
import { makeAutoObservable } from "mobx";
import { clone, equals, memoizeWith } from "ramda";

import { AlarmMessagesFilterEntity } from "entities/filters/AlarmMessagesFilter";

import { string1, toInstance } from "utils/worksolutions-utils";

@Service()
export class AlarmsRuntimeModeFiltersService {
  constructor() {
    makeAutoObservable(this);
  }

  private emptyFiltersData = toInstance(AlarmMessagesFilterEntity)({
    text: undefined,
    severity: undefined,
    endTS: [null, null],
    startTS: [null, null],
  });

  filters: AlarmMessagesFilterEntity = clone(this.emptyFiltersData);

  hasFilter = () => !equals(this.filters, this.emptyFiltersData);

  setFilterFieldValue = memoizeWith(
    string1,
    <KEY extends keyof AlarmMessagesFilterEntity>(field: KEY) =>
      (value: AlarmMessagesFilterEntity[KEY]) => {
        this.filters[field] = value;
      },
  );

  resetFilters = () => {
    this.filters = clone(this.emptyFiltersData);
  };
}
